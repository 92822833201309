<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Recomendation List</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="recommendations"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :fields="fields"
      >
        <template #cell(cover)="data">
          <b-avatar :src="getImage(data.value)" />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>
    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
    >
      <b-card-text>
        <h5>Check First Paragraph</h5>
        Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
        Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar,
  BFormInput, BRow, BCol, BFormGroup, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BFormInput,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      filter: null,
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      fields: [{ key: 'cover', label: 'Photo' }, { key: 'name', label: 'Business Name', sortable: true }, { key: 'state', label: 'state', sortable: true }, { key: 'county', label: 'county', sortable: true }, { key: 'contact_name', label: 'Recommended To', sortable: true }, { key: 'message_sent', label: 'Message' }],
    }
  },
  computed: {
    recommendations() {
      return this.$store.state.recommend.recommendations
    },
    userData() {
      return this.$store.state.auth.user
    },
    rows() {
      return this.$store.state.recommend.total
    },
  },
  mounted() {
    this.getRecomendations()
  },
  methods: {
    getRecomendations() {
      this.$store.dispatch('recommend/getRecommendations', { page: 1, per_page: this.per_page })
    },
    onPageChange(page) {
      return this.$store.dispatch('recommend/getRecommendations', { page, per_page: this.per_page })
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails() {
      this.is_update = true
      this.showModal()
    },
  },
}
</script>

<style>

</style>
